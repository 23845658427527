import React from 'react';
import Button from '@components/Buttons';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import logger from '@utils/logger';

interface IPasscodeInput {
  passcode1: number;
  passcode2: number;
  passcode3: number;
  passcode4: number;
  passcode5: number;
  passcode6: number;
}

const Passcode = ({
  loading,
  error,
  handleFormSubmit,
}: {
  loading: boolean;
  error: boolean;
  handleFormSubmit: (passcode: string) => void;
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors: formError },
  } = useForm();

  const goToPosition = (desiredPosition: number) => {
    const nextElement = document.getElementById(
      `passcode${desiredPosition}`
    ) as HTMLInputElement;
    if (nextElement) {
      nextElement.focus();
      nextElement.value = '';
    }
  };

  const handleKeyUp = (
    event: React.KeyboardEvent<HTMLInputElement>,
    digitPosition: number
  ) => {
    let desiredPosition;
    if (event.key == 'Backspace') {
      logger.debug('Backspace keyUp in position: ', digitPosition);
      desiredPosition = digitPosition - 1;
    }
    if (event.key >= '0' && event.key <= '9') {
      logger.debug('0-9 keyUp in position: ', event);
      desiredPosition = digitPosition + 1;
    }
    if (desiredPosition > 0 && desiredPosition < 7) {
      goToPosition(desiredPosition);
    }
  };

  const compilePasscode = (formData: IPasscodeInput): string => {
    return ''.concat(
      formData.passcode1.toString(),
      formData.passcode2.toString(),
      formData.passcode3.toString(),
      '-',
      formData.passcode4.toString(),
      formData.passcode5.toString(),
      formData.passcode6.toString()
    );
  };

  const passcodeDigit = (digitPosition: number, className?: string) => {
    return (
      <input
        id={`passcode${digitPosition}`}
        data-testid={`passcode-input-${digitPosition}`}
        {...register(`passcode${digitPosition}`, {
          required: true,
          max: 9,
          maxLength: 1,
          pattern: /[0-9]+/,
        })}
        type="number"
        pattern="[0-9]*"
        disabled={loading}
        className={classNames(
          'text-center appearance-none rounded-none relative block w-1/6 h-12 px-2 py-2 border border-gray-300 placeholder-gray-300 shadow-md text-gray-900 focus:outline-none focus:ring-2 focus:ring-brand focus:ring-opacity-50 focus:z-10',
          className,
          (error || Object.keys(formError).length > 0) && 'border-error'
        )}
        placeholder="0"
        onFocus={(e) => {
          e.currentTarget.select();
        }}
        onClick={(e) => {
          e.currentTarget.value = '';
          e.currentTarget.select();
        }}
        onContextMenu={(e) => e.preventDefault()}
        onKeyUp={(e) => {
          handleKeyUp(e, digitPosition);
        }}
      />
    );
  };

  const submitForm = (formData) => {
    const compiledPasscode = compilePasscode(formData);
    logger.debug('Submitting form with passcode: ', compiledPasscode);
    handleFormSubmit(compiledPasscode);
  };

  return (
    <>
      <form className="flex flex-col items-center justify-around w-full">
        {Object.keys(formError).length > 0 && (
          <div
            className="w-full text-sm text-error"
            data-testid="passcode-validation-error-message"
          >
            Please enter a valid access code. The access code should be 6
            digits, 1 in each box.
          </div>
        )}
        {error && (
          <div
            className="w-full pt-2 text-sm text-error"
            data-testid="external-error-message"
          >
            Access code is not valid or has been used a maximum number of times.
            <br />
            Please contact a member of staff for assistance.
          </div>
        )}
        <div className="flex items-center justify-center w-full py-2 -space-x-px">
          {passcodeDigit(1, 'rounded-l-md')}
          {passcodeDigit(2)}
          {passcodeDigit(3, 'rounded-r-md')}
          <div className="p-4">-</div>
          {passcodeDigit(4, 'rounded-l-md')}
          {passcodeDigit(5)}
          {passcodeDigit(6, 'rounded-r-md')}
        </div>
        <Button
          onClick={handleSubmit(submitForm)}
          isLoading={loading}
          buttonContent="Submit"
          dataTestid="submit-passcode"
          styles="bg-brand text-light rounded-xl border border-transparent shadow-md mb-2"
        />
      </form>
    </>
  );
};

export default Passcode;
