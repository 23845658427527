import React from 'react';
import { Loader } from 'react-feather';
import classnames from 'classnames';

interface IButton {
  id?: string;
  isLoading?: boolean;
  buttonContent?: string | JSX.Element;
  loadingContent?: string | Element;
  styles?: string;
  width?: string;
  focusStyles?: string;
  dataTestid?: string;
  onClick();
}

const Button = ({
  id = undefined,
  isLoading = false,
  buttonContent = '',
  loadingContent = '',
  styles = 'bg-brand text-light rounded-xl border border-transparent shadow-md',
  width = '',
  focusStyles = 'focus:outline-none focus:bg-brand focus:text-light hover:bg-brand-accent hover:text-brand',
  dataTestid = undefined,
  onClick,
}: IButton): JSX.Element => {
  const defaultWidth = 'max-w-lg w-full';
  const commonClasses =
    'flex items-center justify-center text-center py-2 duration-500';
  return isLoading ? (
    <button
      id={id}
      name="loading"
      className={classnames(
        commonClasses,
        styles,
        'opacity-50 cursor-not-allowed focus:outline-none',
        width || defaultWidth
      )}
      disabled={true}
      data-testid={dataTestid}
    >
      {loadingContent || <Loader className="animate-spin" />}
    </button>
  ) : (
    <button
      onClick={onClick}
      name="default"
      className={classnames(
        commonClasses,
        styles,
        width || defaultWidth,
        focusStyles
      )}
      data-testid={dataTestid}
    >
      {buttonContent}
    </button>
  );
};

export default Button;
