import React from 'react';
import classnames from 'classnames';

interface SecondaryButtonProps {
  id?: string;
  buttonContent?: string | JSX.Element;
  styles?: string;
  width?: string;
  focusStyles?: string;
  dataTestid?: string;
  onClick();
}

const SecondaryButton = ({
  id = undefined,
  buttonContent = '',
  styles = 'text-brand rounded-xl border border-brand shadow-md',
  width = '',
  focusStyles = 'focus:outline-none hover:bg-brand-accent',
  dataTestid = undefined,
  onClick,
}: SecondaryButtonProps): JSX.Element => {
  const defaultWidth = 'max-w-lg w-full';
  const commonClasses =
    'flex items-center justify-center text-center py-2 duration-500';
  return (
    <button
      id={id}
      onClick={onClick}
      className={classnames(
        commonClasses,
        styles,
        width || defaultWidth,
        focusStyles
      )}
      data-testid={dataTestid}
    >
      {buttonContent}
    </button>
  );
};

export default SecondaryButton;
