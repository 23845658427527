import { gql } from '@apollo/client';

export const ENROL_VISITOR = gql`
  mutation EnrolVisitor($passcode: String!, $visitorId: String) {
    enrolVisitor(passcode: $passcode, visitorId: $visitorId) {
      bearerToken
      registeredTours
      visitorId
      siteId
      tenantId
      expiry
      licenseId
    }
  }
`;
