import React, { useEffect, useState } from 'react';
import { Passcode } from '@components/Form';
import classNames from 'classnames';
import { GLogo } from '@components/Logo';
import { Banner } from '@components/Banner';
import { StandardPageDimension } from '@components/PageFormat';
import { useEnrol } from '@hooks/useEnrol';
import { useRouter } from 'next/router';
import {
  TOUR_WELCOME,
  UNSUPPORTED_BROWSER,
  UNSUPPORTED_DEVICE,
} from '@config/routes';
import logger from '@utils/logger';
import { datadogRum } from '@datadog/browser-rum';
import { isBrowserCompatible, isDeviceCompatible } from '@utils/compatibility';

const Index = () => {
  const [passcode, setPasscode] = useState<string>();
  const [loading, setLoading] = useState<boolean>(true);

  const router = useRouter();
  const { _p } = router.query; // this value is not immediately available, so we use a useEffect hook to get it

  const { enrolWithPasscode, status, accessRecord } = useEnrol();

  useEffect(() => {
    // if the device is unsupported, push to unsupported device page
    if (!isDeviceCompatible()) {
      router.push({
        pathname: UNSUPPORTED_DEVICE,
      });
      return;
    }

    // if the browser is unsupported, push to unsupported browser page
    if (!isBrowserCompatible()) {
      router.push({
        pathname: UNSUPPORTED_BROWSER,
        query: { previousUrlPath: router.asPath },
      });
      return;
    }

    // if access record is undefined, we need to wait until it has a value or is null, i.e it has been checked
    if (accessRecord === undefined) return;

    // if access record is null, and we have a passcode in the url, we set the passcode
    if (accessRecord === null && _p && typeof _p === 'string') {
      logger.debug('passcode found in url, setting it as passcode state', _p);

      setPasscode(_p);
      return;
    }

    // if the access record has a value, and status is enrolled, we push to the welcome page
    if (accessRecord) {
      if (status === 'ENROLLED') {
        datadogRum.addAction('enrol', { autoEnrol: Boolean(_p) });
        logger.debug('Enrolment complete, redirecting to welcome page');
      } else {
        logger.debug('Valid session found, redirecting');
      }

      datadogRum.setRumGlobalContext({ passcode: accessRecord.passcode });
      router.push({
        pathname: TOUR_WELCOME,
        query: { tourId: accessRecord.tourId, _ob_wiz: 1 },
      });
      return;
    }

    // if all of the conditions above fail, we set loading to false, to prompt the user to enter a passcode
    setLoading(false);
  }, [accessRecord, _p, status]);

  useEffect(() => {
    if (loading && passcode) {
      enrolWithPasscode(passcode);
    }
  }, [loading, passcode]);

  return (
    <>
      <Banner />
      <StandardPageDimension>
        <h2
          className={classNames(
            'text-center py-2 border-b-2 w-full',
            process.env.NEXT_PUBLIC_DEPLOY_ENV === 'development' &&
              'text-red-500'
          )}
          data-testid="manual-enrol-title"
        >
          Welcome to Gyde Audio Tours
        </h2>
        <GLogo className="h-24 p-2 text-brand xs:h-32 md:h-48" />
        <div className="flex flex-col w-full max-w-md">
          <div className="w-full py-2 text-dark" data-testid="help-text">
            Please enter your 6-digit passcode then click &quot;Submit&quot;.
          </div>
          <Passcode
            loading={loading}
            error={status === 'ERROR'}
            handleFormSubmit={(passcode) => {
              setLoading(true);
              setPasscode(passcode);
            }}
          />
        </div>
      </StandardPageDimension>
    </>
  );
};

export default Index;
