import { useMutation } from '@apollo/client';
import { ENROL_VISITOR } from '@lib/gql/Mutation/enrolVisitor';
import { useState } from 'react';
import logger from '@utils/logger';
import { datadogRum } from '@datadog/browser-rum';
import { VISITOR_ID_KEY } from '@config/constants';
import { useAccessRecord } from '@providers/AccessRecordContextProvider';

/*
 * useEnrol hook
 * Provides an abstraction over validating passcodes, exchanging passcodes during enrolment and storing access records
 * following successful enrolment
 */

export type Status = 'PENDING' | 'ENROLLED' | 'ERROR';

const useEnrol = (): {
  status: Status;
  enrolWithPasscode: (passcode: string) => void;
  accessRecord: AccessRecord | null | undefined;
} => {
  const [status, setStatus] = useState<Status>('PENDING');
  const [enrolVisitor] = useMutation(ENROL_VISITOR, {
    errorPolicy: 'all',
  });
  const { accessRecord, setAccessRecord } = useAccessRecord();

  const validatePasscode = (passcode: string): boolean => {
    if (passcode.length >= 7) {
      return true;
    } else {
      const validationErrorReason = 'Passcode must be 7 digits long';
      logger.debug('Passcode failed validation, ', validationErrorReason);
      return false;
    }
  };

  const setRumGlobalContext = (_accessRecord: AccessRecord) => {
    datadogRum.addRumGlobalContext('tenant_id', _accessRecord.tenantId);
    datadogRum.addRumGlobalContext('site_id', _accessRecord.siteId);
    datadogRum.addRumGlobalContext('license_id', _accessRecord.licenseId);
    datadogRum.addRumGlobalContext('passcode', _accessRecord.passcode);
    datadogRum.addRumGlobalContext('tourId', _accessRecord.tourId);
    datadogRum.addRumGlobalContext('expiry', _accessRecord.expiry);
  };

  const enrolWithPasscode = (passcode: string) => {
    setStatus('PENDING');
    if (validatePasscode(passcode)) {
      enrolVisitor({
        variables: {
          passcode,
          visitorId: localStorage.getItem(VISITOR_ID_KEY),
        },
      })
        .then((data) => {
          if (data.errors) {
            logger.debug('API response contained GraphQL errors');
            setStatus('ERROR');
          } else {
            const _accessRecord = {
              tenantId: data.data.enrolVisitor.tenantId,
              siteId: data.data.enrolVisitor.siteId,
              token: data.data.enrolVisitor.bearerToken,
              licenseId: data.data.enrolVisitor.licenseId,
              passcode: data.data.enrolVisitor.passcode,
              tourId: data.data.enrolVisitor.registeredTours[0],
              expiry: new Date(data.data.enrolVisitor.expiry * 1_000),
            };
            const visitorId = data.data.enrolVisitor.visitorId;
            try {
              setAccessRecord(_accessRecord);
              localStorage.setItem(VISITOR_ID_KEY, visitorId);
              setStatus('ENROLLED');
              setRumGlobalContext(_accessRecord);
              datadogRum.setUser({ visitorId });
              logger.debug('Enrolment successful', _accessRecord);
            } catch (err) {
              logger.debug('Error storing access record', err);
              setStatus('ERROR');
            }
          }
        })
        .catch((err) => {
          logger.error('API request failed', err);
          setStatus('ERROR');
        });
    } else {
      setStatus('ERROR');
    }
  };

  return { status, enrolWithPasscode, accessRecord };
};

export default useEnrol;
